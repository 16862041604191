import axios from 'axios'
import { API_URL } from './config'

export async function getAirports () {
  return axios.get(`${API_URL}/airports`).then(({ data }) => data)
}

export async function getAirportsCount () {
  return axios.get(`${API_URL}/airports/count`).then(({ data }) => data)
}

export async function getAirportsByprefix (id, orderby) {
  return axios.get(`${API_URL}/airports/prefix?airport=` + id + '&orderby=' + orderby + '&is_search_filter=' + false).then(({ data }) => data)
}

export async function getAirportsByFilter (id, orderby, data) {
  return axios.get(`${API_URL}/airports/prefix?airport=` + id + '&orderby=' + orderby + '&price_min=' + data.min_price + '&price_max=' + data.max_price + '&is_search_filter=' + true + '&beds=' + data.beds + '&bathrooms=' + data.bathrooms + '&airport_distance=' + data.airport_distance + '&max_nights=' + data.max_nights).then(({ data }) => data)
}

export async function getAirportsByprefixFeatured (id) {
  return axios.get(`${API_URL}/airports/featured/prefix?airport=` + id).then(({ data }) => data)
}
