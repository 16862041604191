<template>
  <div
    v-if="visible"
    class="d-flex flex-grow-1 justify-content-center text-center"
  >
    <h1>
      <span class="text-success" style="font-size: 16px">
        <br>
        <i class="fa fa-spinner fa fa-spin fa fa-1x" /> {{ $t(`loading`) }}
      </span>
    </h1>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    visible: { type: Boolean }
  }
}
</script>
