import axios from 'axios'
import { API_URL } from './config'

export async function onGetData (url, data) {
  return axios.get(`${API_URL}/` + url, data).then(({ data }) => data)
}
export async function onPostData (url, data) {
  return axios.post(`${API_URL}/` + url, data).then(({ data }) => data)
}
export async function onDeleteData (url, data) {
  return axios.delete(`${API_URL}/` + url, data).then(({ data }) => data)
}
export async function onUpdateData (url, data) {
  return axios.put(`${API_URL}/` + url, data).then(({ data }) => data)
}

export async function onGetSeverData (url, data) {
  return axios.get(url, data).then(({ data }) => data)
}
