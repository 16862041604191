<template>
  <div class="p-2">
    <div class="rounded bg-white shadow p-8">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card'
}
</script>
