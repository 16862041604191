<template>
  <div class="main-layout w-auto">
    <child />
  </div>
</template>

<script>
export default {
  name: 'BasicLayout'
}
</script>
