// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'
export const UNREAD_MESSAGE = 'UNREAD_MESSAGE'
export const FETCH_UNREAD_MESSAGE_SUCCESS = 'FETCH_UNREAD_MESSAGE_SUCCESS'
export const FETCH_UNREAD_MESSAGE_FAILURE = 'FETCH_UNREAD_MESSAGE_FAILURE'
export const FETCH_COUNT_PENDING_INVITE = 'FETCH_COUNT_PENDING_INVITE'
export const FETCH_COUNT_PENDING_INVITE_SUCCESS = 'FETCH_COUNT_PENDING_INVITE_SUCCESS'
export const FETCH_COUNT_PENDING_INVITE_FAILURE = 'FETCH_COUNT_PENDING_INVITE_FAILURE'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'
