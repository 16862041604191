<template>
  <div>
    <navbar />
  </div>
</template>

<script>
import Navbar from './Navbar'

export default {
  name: 'HeaderComponent',
  components: {
    Navbar
  }
}
</script>
