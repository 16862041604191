<template>
  <div class="text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
    <!-- <div style="background-color: #fff6b4; border: solid 1px #fdd033; padding: 12px; border-radius: 6px; margin: 6px; line-height: 1.75em">
      <span style="font-size: 16px; font-weight: 400">
        Hi all! We have updated our Google Maps code and it will take about 8-12 hours for it to be 'refreshed' on Google's servers. We apologize for the inconvenience and hopefully this will be cleared up in the next few hours. Thank you for understanding and a very sincere thank you for using NurseHousing247.com!
      </span>
    </div> -->

    <div class="flex flex-col px-2 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
      <div class="flex flex-row items-center justify-between p-0 pt-3 md:p-4">
        <div class="md:h-12 ini sm:relative md:static">
          <router-link :to="{ name: 'home' }" class="z-50 -mt-6 md:absolute focus:outline-none lg:-mt-10 md:ml-6 lg:ml-8">
            <img class="h-12 md:h-16 lg:h-20 md:mt-12" src="/assets/img/logo.png">
          </router-link>
        </div>
        <div class="md:hidden">
          <LocaleDropdown />
        </div>
        <button class="mt-1 rounded-lg md:hidden focus:outline-none focus:shadow-outline" @click="toggleMenu">
          <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
            <path v-if="!openMenu" fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clip-rule="evenodd"
            />
            <path v-else fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      <nav v-click-outside="toggleCloseMenu" :class="{ 'flex': openMenu, 'hidden': !openMenu }"
           class="z-40 z-50 flex-col flex-grow pb-3 text-right md:pb-0 md:flex md:justify-end md:flex-row"
           @click="toggleCloseMenu"
      >
        <router-link v-show="userAuth && userAuth.id === 1 && userAuth.status === 'ACTIVE'"
                     class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
                     :to="{ name: 'AccountManager' }"
        >
          Account Mgr.
        </router-link>

        <button v-show="userAuth && userAuth.id != 1 && getAdminTempToken"
                class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
                :to="{ name: 'AccountManager' }"
                @click="handleBackToAdmin()"
        >
          Back to Admin Mgr
        </button>

        <router-link
          class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
          :to="{ name: 'home' }"
        >
          {{ $t(`home`) }}
        </router-link>
        <router-link v-show="!userAuth"
                     class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
                     :to="{ path: '/create-account' }"
        >
          {{ $t(`create_account`) }}
        </router-link>
        <router-link v-show="!userAuth"
                     class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
                     :to="{ name: 'login' }"
        >
          {{ $t(`login`) }}
        </router-link>
        <router-link v-show="userAuth && userAuth.user_type === 0 && userAuth.status === 'ACTIVE'"
                     class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
                     :to="{ name: 'my-properties' }"
        >
          {{ $t(`my_properties`) }}
        </router-link>
        <router-link v-show="userAuth && userAuth.user_type != 0 && userAuth.status === 'ACTIVE'"
                     class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
                     :to="{ name: 'my-crashpad' }"
        >
          {{ $t(`my_crashpad_no_s`) }}
        </router-link>
        <router-link v-show="userAuth && userAuth.status === 'ACTIVE'"
                     class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
                     :to="{ name: 'messages' }"
        >
          {{ $t(`my_messages`) }}
          <span v-if="unread > 0" class="absolute w-4 h-4 px-1 -mt-2 text-xs text-white bg-red-600 rounded-full">{{ unread
          }}</span>
        </router-link>
        <!-- <router-link v-show="userAuth && userAuth.user_type === 0 && userAuth.status === 'ACTIVE'"
          class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
          :to="{ name: 'my-crew' }">
          {{ $t(`my_tenants`) }}
        </router-link> -->
        <router-link v-show="userAuth && userAuth.status === 'ACTIVE'"
                     class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:hidden md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
                     :to="{ path: '/contact' }"
        >
          {{ $t(`contact_us`) }}
        </router-link>
        <router-link v-show="userAuth && userAuth.status === 'ACTIVE'"
                     class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:hidden md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
                     :to="{ name: 'account-setting' }"
        >
          {{ $t(`settings`) }}
        </router-link>
        <a v-show="userAuth && userAuth.status === 'ACTIVE'"
           class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:hidden md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
           @click="onHandleLogout"
        >
          {{ $t(`logout`) }}
        </a>
        <div v-show="userAuth" class="relative hidden md:block">
          <button v-click-outside="hideDropDown"
                  class="px-3 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
                  @click="toggleDropDown"
          >
            <span id="nav_profile">{{ $t(`hi`) }} {{ userAuth && (userAuth.display_name || userAuth.first_name) }}!</span>
            <svg fill="currentColor" viewBox="0 0 20 20"
                 :class="{ 'rotate-180': openDropDown, 'rotate-0': !openDropDown }"
                 class="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1"
            >
              <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
              />
            </svg>
          </button>
          <div v-if="openDropDown" x-transition:enter="transition ease-out duration-100"
               x-transition:enter-start="transform opacity-0 scale-95"
               x-transition:enter-end="transform opacity-100 scale-100" x-transition:leave="transition ease-in duration-75"
               x-transition:leave-start="transform opacity-100 scale-100"
               x-transition:leave-end="transform opacity-0 scale-95"
               class="absolute right-0 z-50 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48"
          >
            <div class="z-50 px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
              <router-link
                class="block px-4 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:hover:text-white dark-mode:text-gray-200 focus:text-gray-900 focus:bg-gray-200"
                :to="{ name: 'account-setting' }"
              >
                {{ $t(`settings`) }}
              </router-link>
              <router-link
                class="block px-4 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:hover:text-white dark-mode:text-gray-200 focus:text-gray-900 focus:bg-gray-200"
                :to="{ path: '/contact' }"
              >
                {{ $t(`contact_us`) }}
              </router-link>
              <a href="javascript:void(0);"
                 class="block px-4 py-2 mt-2 text-sm font-semibold text-blue-500 rounded-lg md:mt-0 hover:text-white hover:bg-blue-500 focus:outline-none focus:shadow-outline dark-mode:hover:text-white dark-mode:text-gray-200 focus:text-gray-900 focus:bg-gray-200"
                 @click="onHandleLogout"
              >
                {{ $t(`logout`) }}
              </a>
            </div>
          </div>
        </div>
        <!-- <div>
          <b-nav-item-dropdown id="flag-selector" class="m-2 list-none">
            <template #button-content>
              <div class="profile">
                <b-icon icon="person-fill"></b-icon>
              </div>
              <img class="default-icon" src="/assets/images/flags/flag-french.png">
            </template>
            <b-dropdown-item href="#" v-for="(available_lang, key) in available_langs " :key="key">
              <div class="flex align-middle">
                <img class="default-icon" :src="'/assets/images/flags/'+ available_lang.flag">
                <span class="pl-2 text-blue-500 uppercase">{{ available_lang.key }}</span>
              </div>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </div> -->
        <!-- Drop-down menu -->
        <!-- <div class="hidden align-middle md:flex">
          <LocaleDropdown/>
        </div> -->
      </nav>
    </div>
  </div>
</template>
<style lang="scss">
#flag-selector {
  border: none !important;

  a {
    border: none !important;
  }

  a::after {
    display: none !important;
    border: none !important;
  }

  a:focus-visible {
    border: none !important;
  }

  .default-icon {
    display: initial;
    max-width: 27px;
    position: relative;
  }

}
</style>
<script>
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'
import * as API_LOGIN from '~/api/login'
import DropdownMenu from './DropdownMenu'
import LocaleDropdown from './LocaleDropdown'

export default {
  components: { DropdownMenu, LocaleDropdown },

  directives: {
    ClickOutside
  },
  data: () => ({
    appName: window.config.appName,
    user: { name: 'ABC', photo_url: '' },
    openDropDown: false,
    openMenu: false,
    userAuth: null,
    isToggleCloseMenu: false,
    available_langs: [
      {
        key: 'en',
        flag: 'flag-uk.png'
      },
      {
        key: 'es',
        flag: 'flag-spanish.png'
      },
      {
        key: 'fr',
        flag: 'flag-french.png'
      },
      {
        key: 'pt',
        flag: 'flag-pt.png'
      }
    ]
  }),

  created () {
    const tempUser = window.localStorage.getItem('user')
    if (tempUser !== 'undefined') {
      this.userAuth = JSON.parse(tempUser)
    }
  },

  computed: mapGetters({
    unread: 'auth/unreadMessages',
    getAdminTempToken: 'admin/getAdminTempToken'
  }),

  methods: {
    async logout () {
      try {
        await API_LOGIN.logout(this.token)
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        window.location.href = '/'
      } catch (e) {
        console.log('error', e)
      }
    },
    toggleMenu () {
      if (!this.openMenu && !this.isToggleCloseMenu) {
        this.openMenu = true
      } else {
        this.openMenu = false
      }
      this.isToggleCloseMenu = false
    },
    toggleCloseMenu () {
      if (this.openMenu && !this.openDropDown) {
        this.openMenu = false
        this.isToggleCloseMenu = true
      } else {
        this.isToggleCloseMenu = false
      }
    },
    toggleDropDown () {
      this.openDropDown = !this.openDropDown
    },
    hideDropDown () {
      this.openDropDown = false
    },
    async onHandleLogout () {
      try {
        await API_LOGIN.logout(this.token)
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        window.location.href = '/'
      } catch (e) {
        console.log('error', e)
      }
    },
    async handleBackToAdmin () {
      await this.$store.dispatch('admin/backToAdmin')
    }
  }
}
</script>
