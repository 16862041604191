import Vue from 'vue'
import store from '~/store'
import router from '~/router'
import i18n from '~/plugins/i18n'
import App from '~/components/App'

import moment from 'moment'
import VueRouter from 'vue-router'
import FatalError from '~/shared/components/FatalError'
import StarRating from '~/shared/components/StarRating'
import Success from '~/shared/components/Success'
import ValidationErrors from '~/shared/components/ValidationErrors'

import BootstrapVue from 'bootstrap-vue'
import TinyMceEditor from '@tinymce/tinymce-vue'
import FullCalendar from 'vue-full-calendar'
import ReadMore from 'vue-read-more'
import rate from 'vue-rate'
import * as VueGoogleMaps from 'vue2-google-maps'
import ToggleButton from 'vue-js-toggle-button'
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import VueIntro from 'vue-introjs'
import VueAnalytics from 'vue-analytics'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

import '~/plugins'
import '~/components'
import 'vue-rate/dist/vue-rate.css'
import Spinner from '~/components/Spinner'
import { S3 } from '~/api/config'
const numeral = require('numeral')
window._ = require('lodash')
window.introJs = require('intro.js')

Vue.config.productionTip = false

Vue.filter('fromNow', (value) => moment(value).fromNow())

// Assign API key to all editor instances
const ConfiguredTinyMceEditor = Vue.extend({
  extends: TinyMceEditor,
  props: {
    'apiKey': {
      // default: 'no-api-key'
      default: 'x8qcwopqqu739pdlg3t0463wh7y3snxwuhbrzavvf905f92y'
    }
  }
})

Vue.component('Tinymce', ConfiguredTinyMceEditor)
Vue.component('VSelect', vSelect)
Vue.component('DownloadExcel', JsonExcel)
Vue.component('StarRating', StarRating)
Vue.component('FatalError', FatalError)
Vue.component('Success', Success)
Vue.component('VErrors', ValidationErrors)
Vue.component('Spinner', Spinner)
Vue.use(VueTelInput)

Vue.use(rate)
Vue.use(BootstrapVue)
Vue.use(FullCalendar)
Vue.use(ToggleButton)
Vue.use(ReadMore)
Vue.use(VueIntro)
Vue.use(VueRouter)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.MIX_GOOGLE_MAP_KEY,
    libraries: 'places'
  }
})

// Vue.use(Tawk, {
//   tawkSrc: 'https://embed.tawk.to/5fb37e691535bf152a56b391/default'
// })
Vue.use(VueAnalytics, {
  router,
  id: process.env.MIX_GOOGLE_ANALYTIC_KEY
})
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment
      .utc(String(value))
      .local()
      .format('MMM D, YYYY')
  }
})
Vue.filter('formatDateNoTime', function (value) {
  if (value) {
    return moment
      .utc(String(value))
      .local()
      .format('MMM D, YYYY')
  }
})
Vue.filter('lastChangedDate', function (value) {
  if (value) {
    return moment
      .utc(String(value))
      .local()
      .format('MMM D, YYYY [at] H:mm')
  }
})
Vue.filter('YearFormat', function (value) {
  if (value) {
    return moment(String(value), 'YYYYMMDD').fromNow()
  }
})
Vue.filter('formatDateOnlyNumber', function (value) {
  if (value) {
    return moment
      .utc(String(value))
      .local()
      .format('MMM D, YYYY')
  }
})
Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment
      .utc(String(value))
      .local()
      .format('MMM D, YYYY')
  }
})
Vue.filter('formatDateUser', function (value) {
  if (value) {
    return moment
      .utc(String(value))
      .local()
      .format('ll')
  }
})
Vue.filter('formatDateCalendarr', function (value) {
  if (value) {
    return moment(String(value)).calendar()
  }
})
Vue.filter('formatDateFeatured', function (value) {
  if (value) {
    let before_format = moment
      .utc(String(value))
      .local()
      .format('DD/MM/YYYY')
    let before = moment.utc(String(before_format), 'DD/MM/YYYY')
    let now_format = moment
      .utc(String(new Date()))
      .local()
      .format('DD/MM/YYYY')
    let now = moment.utc(String(now_format), 'DD/MM/YYYY')
    return before.diff(now, 'days') + ' days remaining'
  }
})
Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0')
})
Vue.filter('striphtml', function (value, characters) {
  let div = document.createElement('div')
  div.innerHTML = value
  let text = div.textContent || div.innerText || ''
  return text.slice(0, characters)
})
Vue.mixin({
  methods: {
    storageUrl: (photo) => (_.isEmpty(photo) ? null : S3 + photo),
    imageUrl: (photo) => (_.isEmpty(photo) ? '/assets/img/default.png' : photo),
    getNameUser (firstname, lastname, displayName) {
      return _.isEmpty(displayName) ? `${firstname} ${lastname}` : displayName
    },
    backToTop () {
      console.info('top')
      return window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
})

/* eslint-disable no-new */
new Vue({
  i18n,
  store,
  router,
  ...App
})
