<template>
  <div>
    <div v-for="(error, index) in errors" :key="key(index)" class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['errors'],
  methods: {
    key (index) {
      return `validation_error_${index}_${Math.random()}`
    }
  }
}
</script>
