import axios from 'axios'
import { API_URL } from './config'

export async function login (data) {
  return axios.post(`${API_URL}/login`, data).then(({ data }) => data)
}

export async function loginwithsocial (data) {
  return axios
    .post(`${API_URL}/login-With-social`, data)
    .then(({ data }) => data)
}

export async function forgot (data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/recovery/password`, data)
      .then(({ data }) => resolve(data))
      .catch(function (error) {
        reject(error.response)
      })
  })
}

export async function resetPassword (data) {
  return axios
    .post(`${API_URL}/reset/password`, data)
    .then(({ data }) => data)
}

export async function logout (data) {
  return axios.post(`${API_URL}/logout`, data).then(({ data }) => data)
}

export async function verifyEmail (data) {
  return axios
    .post(`${API_URL}/verify-email`, data)
    .then(({ data }) => data)
}

export async function verifySocial (data) {
  return axios
    .post(`${API_URL}/verify-social`, data)
    .then(({ data }) => data)
}

export async function verifyTokenEmail (data) {
  return axios
    .post(`${API_URL}/verify-token-email`, data)
    .then(({ data }) => data)
}

export async function verifyTokenNewEmail (data) {
  return axios
    .post(`${API_URL}/verify-token-new-email`, data)
    .then(({ data }) => data)
}
