<template>
  <div class="flex justify-center w-full">
    <div class="flex flex-col w-full min-h-screen lg:w-container">
      <header-component class="mb-4 flex-start" />
      <div class="relative flex flex-grow mb-2 -mt-4 md:mt-2">
        <div
          class="absolute hidden p-2 -mt-10 bg-blue-500 rounded-t-lg md:block flex-end right-4"
        >
          <router-link
            :to="{ name: 'cities' }"
            class="text-lg font-semibold text-white"
          >
            {{ $t(`see_cp_near`) }} {{ about_item.airports }}
            {{ $t(`airports`) }}
          </router-link>
        </div>
        <div id="maincontent" class="relative w-full p-2 bg-white">
          <div class="hidden lg:block xl:block 2xl:block">
            <img class="absolute -ml-56 h-96 top-20" :src="girl_bg">
          </div>
          <child />
        </div>
      </div>
      <footer-component class="flex-end" />
    </div>

    <b-modal
      ref="modalRefGoToMyCrashPad"
      hide-footer
      :title="$t(`pending_invitation`)"
    >
      <div class="text-center d-block" />
      <div class="text-center">
        <p class="mt-1 mb-3 text-base font-medium text-left md:font-normal">
          {{
            $t(
              `you_have_a_pending_invitation_to_join_a_crashpad_please_go_to_my_crashpad_to_accept_invitation`
            )
          }}
        </p>
        <b-btn
          class="mt-3 mr-1"
          style="width: 30%"
          @click="hideModalGoToMyCrashPad()"
        >
          {{ $t(`cancel`) }}
        </b-btn>
        <b-btn
          class="mt-3 ml-1"
          variant="primary"
          style="width: 30%"
          @click="onHandleGoToMyCrashPad()"
        >
          {{ $t(`ok_cool`) }}
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>
<script>
import io from 'socket.io-client'
import * as config from '~/api/config'
import axios from 'axios'
import { mapGetters } from 'vuex'
import * as API_USERS from '~/api/user'
import * as API_AIRPORTS from '~/api/airports'
import * as API_SERVICES from '~/api/services'
import EventBus from '~/helper/EventBus'
import * as API_SERVICE from '~/api/services'
import HeaderComponent from '~/components/HeaderComponent'
import FooterComponent from '~/components/FooterComponent'
var moment = require('moment')

const socket = io(config.SOCKET_URL)
let girl_bgs = [
  '/assets/images/girl_1.png',
  '/assets/images/girl_2.png',
  '/assets/images/girl_3.png'
]
let girl_bg = girl_bgs[Math.floor(Math.random() * girl_bgs.length)]
export default {
  name: 'MainLayout',

  components: {
    HeaderComponent,
    FooterComponent
  },
  data () {
    return {
      girl_bg: girl_bg,
      appVarian: '',
      ready_security: false,
      userAuth: [],
      unread: 0,
      about_item: {
        users: 0,
        listings: 0,
        selisting: 0,
        total_emails: 0,
        airports: 0
      },
      unread_calendar: 0
    }
  },
  watch: {
    $route (to, from) {
      const path = this.$route.path
      const hideInUrl = [
        '/my-crashpad',
        '/account-setting',
        '/billing-information'
      ]
      this.currentRoute = to.name
      if (!hideInUrl.includes(path) && this.countPendingInvites > 0) {
        this.$refs.modalRefGoToMyCrashPad.show()
      }
    }
  },
  computed: mapGetters({
    countPendingInvites: 'auth/countPendingInvite'
  }),
  async created () {
    // before created
    this.appVarian = process.env.MIX_APP_ENV
    var root = this
    if (!JSON.parse(window.localStorage.getItem('about_item'))) {
      localStorage.removeItem('about_item_today')
    }

    if (!JSON.parse(window.localStorage.getItem('about_item_today'))) {
      window.localStorage.setItem(
        'about_item_today',
        JSON.stringify(moment().format('LL'))
      )

      setTimeout(function () {
        root.onHandleCountAirports()
      }, 3000)
    }
    const isRoot = /^(\/|\/index\.asp|\/index\.aspx)$/i.test(location.pathname)
    if (isRoot) {
      setTimeout(function () {
        if (
          moment(
            JSON.parse(window.localStorage.getItem('about_item_today')),
            'MMMM DD, YYYY'
          ) < moment()
        ) {
          root.onHandleCountAirports()
        }
      }, 3000)
    }
    // after created

    const tempUser = window.localStorage.getItem('user')
    if (tempUser !== 'undefined') {
      this.userAuth = JSON.parse(tempUser)
    }

    this.token = JSON.parse(window.localStorage.getItem('token'))
    if (this.token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token
      // this.onHandleCheckUnreadMessage()
      this.onHandleCheckAccount()
      this.onHandleConnect()
      if (JSON.parse(window.localStorage.getItem('show_me_tips')) === null) {
        window.localStorage.setItem('show_me_tips', JSON.stringify(true))
      }

      // Fetch the unread messages.
      this.$store.dispatch('auth/fetchUnreadMessage')
      this.$store.dispatch('auth/fetchCountPendingInvite')

      socket.on('message', (data) => {
        if (data.data.action === 'chat') {
          if (data.data.body.to === this.userAuth.id) {
            this.$store.dispatch('auth/fetchUnreadMessage')
          }
        }
      })

      socket.on('invite', (data) => {
        if (data.data.action === 'sent') {
          if (data.data.body.to_user === this.userAuth.id) {
            this.$store.dispatch('auth/fetchCountPendingInvite')
          }
        }
      })

      const response = await API_SERVICES.onGetData(
        'user/get-count-pending-invitation'
      )
      const path = this.$route.path
      const hideInUrl = [
        '/my-crashpad',
        '/account-setting',
        '/billing-information'
      ]
      if (!hideInUrl.includes(path) && response.count === 1) {
        this.$refs.modalRefGoToMyCrashPad.show()
      }
    }
    if (!JSON.parse(window.localStorage.getItem('user'))) {
      localStorage.removeItem('tenant_total')
    }
    let createdDate = moment(new Date()).utc().format()
    if (JSON.parse(window.localStorage.getItem('isKeepMe')) < createdDate) {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('isKeepMe')
      window.localStorage.removeItem('user')
    }

    if (JSON.parse(window.localStorage.getItem('about_item'))) {
      this.about_item = JSON.parse(window.localStorage.getItem('about_item'))
    }
  },
  mounted () {
    socket.emit('new-user-coming')
    console.log('total-user123123')
    socket.on('total-user', ({ totalUser }) => {
      this.$store.dispatch('general/updateTotalUserAction', totalUser)
    })
  },
  methods: {
    async onHandleNotification (url) {
      try {
        const response = await API_SERVICE.onGetData(url)
        this.unread_calendar = response.data
      } catch (e) {
        console.log('error', e)
      }
    },
    async onHandleCountAirports () {
      console.log('onHandleCountAirports')
      try {
        const response = await API_AIRPORTS.getAirportsCount()

        window.localStorage.setItem('about_item', JSON.stringify(response))
        this.about_item = JSON.parse(window.localStorage.getItem('about_item'))
        this.about_item.airports = JSON.parse(
          window.localStorage.getItem('about_item')
        ).airports

        EventBus.$emit(
          'about_item',
          JSON.parse(window.localStorage.getItem('about_item'))
        )
        window.localStorage.setItem(
          'about_item_today',
          JSON.stringify(moment().format('LL'))
        )
      } catch (e) {
        console.log('error', e)
      }
    },
    async onHandleConnect () {},
    // async onHandleCheckUnreadMessage () {
    //   try {
    //     const response = await API_USERS.getUnreadMessage()
    //     this.unread = response.count
    //   } catch (e) {
    //     console.log('error', e)
    //   }
    // },
    async onHandleCheckAccount () {
      try {
        const response = await API_USERS.checkingAccount()
        window.localStorage.setItem('user', JSON.stringify(response.user))
        window.localStorage.setItem('token', JSON.stringify(response.token))
        window.localStorage.setItem(
          'has_auto_payment',
          response.has_auto_payment
        )
        this.userAuth = JSON.parse(window.localStorage.getItem('user'))
        this.token = JSON.parse(window.localStorage.getItem('token'))
      } catch (e) {
        console.log(e)
      }
    },
    hideModalGoToMyCrashPad () {
      this.$refs.modalRefGoToMyCrashPad.hide()
    },
    onHandleGoToMyCrashPad () {
      if (this.currentRoute!=='my-crashpad') {
        this.$router.push({ path: '/my-crashpad' })
      }
      this.$refs.modalRefGoToMyCrashPad.hide()
    }
  }
}
</script>
