export const state = {
  totalUser: 0
}

// getters
export const getters = {
  totalUser: (state) => state.totalUser
}

// mutations
export const mutations = {
  updateTotalUser (state, total) {
    state.totalUser = total
  }
}

// actions
export const actions = {
  async updateTotalUserAction ({ commit }, total) {
    try {
      commit('updateTotalUser', total)
    } catch (e) {}
  }
}
