<template>
  <div id="footerlinks">
    <div class="grid relative md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4">
      <div class="linkbox">
        <h4 class="text-base text-white font-semibold my-2">
          {{ $t(`what_cha_need_to_know`) }}
        </h4>
        <ul class="font-medium md:font-normal">
          <li>
            <router-link
              to="/why"
            >
              {{ $t(`why_list_a_property`) }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/policies"
            >
              {{ $t(`refunds`) }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/policies"
            >
              {{ $t(`your_information`) }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/policies"
            >
              {{ $t(`policies`) }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/house-rules"
            >
              {{ $t(`house_rules`) }}
            </router-link>
          </li>
        </ul>
      </div>
      <div class="linkbox">
        <h4 class="text-base text-white font-semibold my-2">
          {{ $t(`resources`) }}
        </h4>
        <ul class="font-medium md:font-normal">
          <li>
            <router-link
              to="/forgot"
            >
              {{ $t(`forgot_password`) }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/problems"
            >
              {{ $t(`website_problems`) }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/help"
            >
              {{ $t(`contact_help`) }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/advertise"
            >
              {{ $t(`advertise_here`) }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/help"
            >
              {{ $t(`faq`) }}
            </router-link>
          </li>
        </ul>
      </div>

      <div class="linkbox">
        <h4 class="text-base text-white font-semibold my-2">
          Coming soon...
        </h4>
        <ul class="font-medium md:font-normal">
          <li>
            <router-link
              to="/tenant-management"
            >
              {{ $t(`tenant_management`) }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/autopay"
            >
              {{ $t(`automatic_rent_payments`) }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/tenant-messaging"
            >
              {{ $t(`tenant_messaging`) }}
            </router-link>
          </li>
          <!-- <li>
            <router-link
              to="/coming-soon"
            >
              Hot-Bed Management
            </router-link>
          </li>
          <li>
            <router-link
              to="/quality-listings"
            >
              Quality Listings
            </router-link>
          </li> -->
        </ul>
      </div>

      <div class="hidden lg:block xl:block 2xl:block">
        <img class="bottom-0 right-4 absolute h-52" src="/assets/images/homelink_girlimage.png" style="height: 95%">
      </div>
    </div>
    <div id="footer">
      <div class="inner">
        <div id="twitter" style="float: left; width: 71px; height: 20px">
          <a
            href="https://twitter.com/share"
            class="twitter-share-button"
            data-count="horizontal"
            data-via="CrashPad411"
          >Tweet</a>
        </div>
        <div id="fb-root" />
        <div
          class="fb-like"
          data-href="http://www.facebook.com/NurseHousing247"
          data-layout="button_count"
          data-action="like"
          data-size="small"
          data-show-faces="false"
          data-share="true"
        />
        <div class="copyright -mt-2" style="float: right">
          {{ year }} NurseHousing247 &copy; Travel Bug Ventures Ltd.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'FooterComponent',
  data () {
    return {
      year: moment().format('YYYY')
    }
  }
}
</script>
