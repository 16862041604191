import store from '~/store'
import * as API_USERS from '~/api/user'
import axios from 'axios'
export default async (to, from, next) => {
  const token = JSON.parse(window.localStorage.getItem('token'))
  if (!token) {
    next({ name: 'login' })
  } else {
    let user = store.getters['auth/user']
    if (user && user.id === 1) {
      next()
    } else if (!user && token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      await store.dispatch('auth/fetchUser')
      user = store.getters['auth/user']
      if (user.id === 1) {
        next()
      } else {
        next({ name: 'dashboard.home' })
      }
    } else {
      next({ name: 'dashboard.home' })
    }
  }
}
