import axios from 'axios'
export const state = {
  places: [],
  isLoading: false
}

// getters
export const getters = {
  places: (state) => state.places,
  loading: (state) => state.isLoading
}

// mutations
export const mutations = {
  updatePreferences (state, data) {
    state.isLoading = false
    state.places = data
  },
  updateLoading (state, status) {
    state.isLoading = status
  }
}

// actions
export const actions = {
  async fetchData ({ commit }) {
    try {
      commit('updateLoading', true)
      const { data } = await axios.get('/api/places')
      commit('updatePreferences', data)
    } catch (e) {}
  },
  async createData ({ dispatch }, data) {
    try {
      await axios.post('/api/places', data)
      dispatch('fetchData')
    } catch (e) {
      console.log(e)
    }
  },
  async updateData ({ commit, dispatch }, { id, name }) {
    try {
      const { data } = await axios.patch('/api/places/' + id, { name })
      dispatch('fetchData')
    } catch (e) {
      console.log('er', e)
    }
  },
  async deleteData ({ dispatch }, id) {
    try {
      await axios.delete('/api/places/' + id)
      dispatch('fetchData')
    } catch (e) {}
  }
}
