<template>
  <div class="flex items-center focus:ring-0 visited:ring-0">
    <b-nav-item-dropdown id="flag-selector" class="mb-0 list-none">
      <template #button-content>
        <img
          class="mt-1 default-icon"
          :src="
            locale
              ? '/assets/images/flags/' + locale + '.png'
              : '/assets/images/flags/en.png'
          "
        >
      </template>
      <!-- Drop-down menu -->
      <b-dropdown-item
        v-for="(available_lang, key) in locales"
        :key="key"
        href="#"
        :disabled="key === locale"
        @click="setLocale(key)"
      >
        <button class="flex align-middle">
          <img
            class="default-icon "
            :src="
              key
                ? '/assets/images/flags/' + key + '.png'
                : '/assets/images/flags/en.png'
            "
          >
          <span
            class="pl-2 text-sm font-semibold uppercase md:mt-0 hover:text-white focus:outline-none focus:shadow-outline dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200"
          >{{ available_lang }}</span>
        </button>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>
<style>

#flag-selector ul li a.disabled {
  opacity: 50%;
}
</style>
<script>
import { mapGetters } from 'vuex'
import { loadMessages } from '~/plugins/i18n'

export default {
  computed: mapGetters({
    locale: 'lang/locale',
    locales: 'lang/locales'
  }),

  methods: {
    async setLocale (locale) {
      // if (this.$i18n.locale !== locale) {
      loadMessages(locale)
      await this.$store.dispatch('lang/setLocale', { locale })
      window.location.reload()
      // }
    }
  }
}
</script>
