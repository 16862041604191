/* eslint-disable no-return-await */
import axios from 'axios'
import { API_URL } from './config'

export async function verifyEmail (data) {
  return axios.post(`${API_URL}/verify-email`, data).then(({ data }) => data)
}

export async function changePassword (data) {
  return axios.post(`${API_URL}/change-password`, data).then(({ data }) => data)
}

export async function verifySocial (data) {
  return axios.post(`${API_URL}/verify-social`, data).then(({ data }) => data)
}

export async function updateAccount (data) {
  return axios.post(`${API_URL}/change-email`, data).then(({ data }) => data)
}

export async function checkingAccount (data) {
  return axios.get(`${API_URL}/checking-account`).then(({ data }) => data)
}

export async function uploadImg (formData) {
  return axios.post(`${API_URL}/upload/user/images`, formData).then(x => x.data)
}

export async function updateAccountSetting (data) {
  return axios.post(`${API_URL}/account/user/update`, data).then(({ data }) => data)
}

export async function getUserProfile (data) {
  return axios.get(`${API_URL}/user/public/` + data).then(({ data }) => data)
}
export async function getUnreadMessage (data) {
  return axios.get(`${API_URL}/unread/messages`, data).then(({ data }) => data)
}

export async function createUserReview (data) {
  return axios.post(`${API_URL}/user/review`, data).then(({ data }) => data)
}

export async function createReviewResponse (data) {
  return axios.post(`${API_URL}/user/review-response`, data).then(({ data }) => data)
}

// chantz -
export async function updateStripeCard (data) {
  return axios.post(`${API_URL}/user/update-stripe-card`, data).then(({ data }) => data)
}

// public api acccess
export async function getPublicUser (data) {
  return axios.get(`${API_URL}/user/` + data).then(({ data }) => data)
}
export async function getPublicReview (data) {
  return axios.get(`${API_URL}/user/review/` + data).then(({ data }) => data)
}
export async function updateUserPicture (data) {
  return axios.post(`${API_URL}/user/change-picture`, data).then(({ data }) => data)
}

export async function updateAutomaticPayment (status) {
  return axios.put(`${API_URL}/lister/update-automatic-payment`, { status: status }).then(({ data }) => data)
}

export async function updatePayoutConfig (config_id) {
  return axios.put(`${API_URL}/lister/update-payout-config`, { config_id: config_id }).then(({ data }) => data)
}

export async function getSeekerAccountService () {
  return axios.get(`${API_URL}/seeker-account-service`).then(({ data }) => data)
}

export async function getListerAccountService () {
  return axios.get(`${API_URL}/lister-account-service`).then(({ data }) => data)
}

export async function checkExistingEmail (data) {
  return axios.post(`${API_URL}/user/email-exist`, data).then(({ data }) => data)
}

export async function removeListingOrFeature (data) {
  return axios.post(`${API_URL}/lister/remove-listing-or-feature`, data).then(({ data }) => data)
}

export async function getPublicUserByListing (id) {
  console.log('id', id)
  return axios.get(`${API_URL}/listing-owner/` + id).then(({ data }) => data)
}

export async function getPublicListingReview (id) {
  return axios.get(`${API_URL}/listing/review/` + id).then(({ data }) => data)
}
