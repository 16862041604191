import axios from 'axios'
import Cookies from 'js-cookie'
import store from '~/store'
import * as API_USERS from '~/api/user'

// state
export const state = {
  adminTempToken: Cookies.get('adminTempToken'),
  userSwitching: false
}

// getters
export const getters = {
  getAdminTempToken (state) {
    return state.adminTempToken
  },
  getUserSwitching (state) {
    return state.userSwitching
  }
}

// mutations
export const mutations = {
  updateAdminUserSwitching (state, status) {
    return (state.userSwitching = status)
  },
  updateAdminTempToken (state, token) {
    Cookies.set('adminTempToken', token)
    return state.adminTempToken
  },
  async updateUserToken (state, token) {
    window.localStorage.setItem('token', JSON.stringify(token))
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    const response = await API_USERS.checkingAccount()
    window.localStorage.setItem('user', JSON.stringify(response.user))
    window.localStorage.setItem('user_has_auto_payment', response.has_auto_payment)
    window.location.href = '/messages'
  }
}

// actions
export const actions = {
  async switchUser ({ commit }, id) {
    let oldToken =
      store.getters['auth/token'] || window.localStorage.getItem('token')
    try {
      commit('updateAdminUserSwitching', true)
      await axios
        .get(`/api/admin/switch-user/${id}`)
        .then(({ data: { token } }) => {
          commit('updateUserToken', token)
          commit('updateAdminTempToken', oldToken)
        })
    } catch (e) {
      console.log(e)
    }
    commit('updateAdminUserSwitching', false)
  },
  async backToAdmin () {
    let tempToken = JSON.parse(Cookies.get('adminTempToken'))
    localStorage.setItem('token', JSON.stringify(tempToken))
    Cookies.remove('adminTempToken')
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + tempToken
    const response = await API_USERS.checkingAccount()
    window.localStorage.setItem('user', JSON.stringify(response.user))
    localStorage.setItem('user_has_auto_payment', response.has_auto_payment)
    window.location.href = '/messages'
  }
}
