import axios from 'axios'
import store from '~/store'
import router from '~/router'
import Swal from 'sweetalert2'
import i18n from '~/plugins/i18n'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// Request interceptor
axios.interceptors.request.use(request => {
  const token = store.getters['auth/token']
  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`
  }

  const locale = store.getters['lang/locale']
  if (locale) {
    request.headers.common['X-Locale'] = locale
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return request
})

// Response interceptor
axios.interceptors.response.use(response => response, error => {
  const { status } = error.response

  if (status >= 500) {
    Swal.fire({
      type: 'error',
      title: i18n.t('error_alert_title'),
      text: i18n.t('error_alert_text'),
      reverseButtons: true,
      confirmButtonText: i18n.t('ok'),
      cancelButtonText: i18n.t('cancel')
    })
  } else if (status === 409) {
    const { data } = error.response
    Swal.fire({
      type: 'warning',
      title: i18n.t('error_alert_title'),
      text: data.message,
      reverseButtons: true,
      confirmButtonText: i18n.t('ok'),
      cancelButtonText: i18n.t('cancel')
    })
  }

  // if (status === 401 && store.getters['auth/check']) {
  else if (status === 401) {
    Swal.fire({
      type: 'warning',
      title: i18n.t('token_expired_alert_title'),
      text: i18n.t('token_expired_alert_text'),
      reverseButtons: true,
      confirmButtonText: i18n.t('ok'),
      cancelButtonText: i18n.t('cancel')
    }).then((result) => {
      if (result.value) {
        // store.commit('auth/LOGOUT')

        // router.push({ name: 'login' })

        localStorage.removeItem('token')
        localStorage.removeItem('user')
        window.location.href = '/login'
      }
    })
  }

  return Promise.reject(error)
})
