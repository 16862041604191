function page(path) {
	return () =>
		import(/* webpackChunkName: '' */ `~/pages/${path}`).then(
			(m) => m.default || m
		);
}
export default [
	{ 
    path: '/', 
    name: 'home', 
    component: page('home/index.vue') 
  },
	{ 
    path: '/login', 
    name: 'login', 
    component: page('auth/login.vue') 
  },
	{
    path: '/forgot',
    component: page('auth/password/forgot.vue'),
    name: 'forgot-password',
    meta: {
      title:
        'Forgot Password - Flight Attendant, Pilot, CrashPads, Air Crew, Inflight, CrashPad Listings'
    }
  },
	{
		path: '/create-account',
		name: 'register',
		component: page('auth/register/index.vue')
	},
  {
		path: '/create-account/join-crashpad',
		component: page('auth/register/invitationUser.vue'),
		name: 'create-account'
	  },
	{
		path: '/create-account/:type',
		component: page('auth/register/information.vue'),
		name: 'create-account-type'
	  },
  {
    path: '/translations',
    component: page('translations/index.vue'),
    name: 'Translations'
  },
  {
    path: '/account-manager',
    component: page('service-pages/AccountManager.vue'),
    name: 'AccountManager'
  },
	{
		path: '/notice-of-understanding',
		component: page('auth/register/noticeOfUnderstanding.vue'),
		name: 'notice-of-understanding',
		meta: {
		  title:
			'Notice of Understanding - Flight Attendant, Pilot, CrashPads, Air Crew, Inflight, CrashPad Listings'
		}
	},
	{
    path: '/terms', name: 'terms', component: page('legal/terms.vue')
  },
	{
		path: '/password/reset',
		name: 'password.request',
		component: page('auth/password/email.vue')
	},
	{
		path: '/password/reset/:token',
		name: 'password.reset',
		component: page('auth/password/reset.vue')
	},
	{
		path: '/email/verify/:id',
		name: 'verification.verify',
		component: page('auth/verification/verify.vue')
	},
	{
		path: '/email/resend',
		name: 'verification.resend',
		component: page('auth/verification/resend.vue')
	},
	{ path: '/dashboard', name: 'dashboard.home', component: page('home.vue') },
	{
		path: '/dashboard/components',
		name: 'dashboard.components',
		component: page('components.vue')
	},
	// {
	// 	path: '/settings',
	// 	component: page('settings/index.vue'),
	// 	children: [
	// 		{ path: '', redirect: { name: 'settings.profile' } },
	// 		{
	// 			path: 'profile',
	// 			name: 'settings.profile',
	// 			component: page('settings/profile.vue')
	// 		},
	// 		{
	// 			path: 'password',
	// 			name: 'settings.password',
	// 			component: page('settings/password.vue')
	// 		}
	// 	]
	// },
  {
		path: '/contact',
		component: page('service-pages/Contact.vue'),
		name: 'Contact'
	},
	{
		path: '/create-crashpad',
		component: page('dashboard/crashpad/create.vue'),
		name: 'create-crashpad'
	},
	{
    path: '/listing/:id',
		component: page('detail/index.vue'),
		name: 'listing'
	},
  {
    path: '/messages',
    component: page('dashboard/message/index.vue'),
    name: 'messages'
  },
  {
    path: '/my-properties',
    component: page('dashboard/crashpad/index.vue'),
    name: 'my-properties'
  },
  {
    path: '/my-properties/:id/edit',
    component: page('dashboard/crashpad/edit.vue'),
    name: 'edit-crashpad'
  },
  {
    path: '/my-crew',
    component: page('dashboard/crew/index.vue'),
    name: 'my-crew'
  },
	{
		path: '/search',
		component: page('crashpads/index.vue'),
		name: 'search'
	},
  {
    path: '/security',
    component: page('service-pages/Security.vue'),
    name: 'Security'
  },
	{
		path: '/account-setting',
		component: page('dashboard/user/account.vue'),
		name: 'account-setting'
	},
	{
		path: '/review-services',
		component: page('dashboard/renew/upgradeLister.vue'),
		name: 'review-services'
	},
	{
		path: '/upgrade-account',
		component: page('dashboard/renew/upgradeLister.vue'),
		name: 'upgrade-account'
	},
	{
		path: '/billing-information',
		component: page('dashboard/bill/payment.vue'),
		name: 'billing-information'
	},
	{
    path: '/processing/payment',
    component: page('dashboard/bill/payment.vue'),
    name: 'processing-payment',
    meta: {
      title:
        'Processing Payment - Flight Attendant, Pilot, CrashPads, Air Crew, Inflight, CrashPad Listings'
    }
  },
	{
		path: '/chat/:id',
		component: page('dashboard/message/chat.vue'),
		name: 'chat'
	},
	{
    path: '/message-to/user/:user_id/listing/:listing_id',
    component: page('dashboard/message/new.vue'),
    name: 'message-to',
    meta: {
      title:
        'Message to - Flight Attendant, Pilot, CrashPads, Air Crew, Inflight, CrashPad Listings'
    }
  },
  {
		path: '/my-crashpad',
		component: page('dashboard/MyCrashPad/index.vue'),
		name: 'my-crashpad'
	},
	{
    path: '/user/:id',
    component: page('review/index.vue'),
    name: 'review-user',
    meta: {
      title:
        'Review User - Flight Attendant, Pilot, CrashPads, Air Crew, Inflight, CrashPad Listings'
    }
  },
  {
    path: '/listing/review/:id',
    component: page('review/listing.vue'),
    name: 'review-listing',
    meta: {
      title:
        'Review Listing - Flight Attendant, Pilot, CrashPads, Air Crew, Inflight, CrashPad Listings'
    }
  },
	{
    path: '/user/verify/:token',
    component: page('auth/verification/verify.vue'),
    name: 'user-verify'
  },
  {
    path: '/tenant/verify-token/:token',
    component: page('auth/verification/tenantVerify.vue'),
    name: 'tenant-verify-token'
  },
  {
    path: '/verify/email/:token',
    component: page('auth/password/verify.vue'),
    name: 'verify-reset-password'
  },
	{
    path: '/lister/payment-history',
    component: page('dashboard/crew/history.vue'),
    name: 'payment-history',
    meta: {
      title:
        'Payment History - Flight Attendant, Pilot, CrashPads, Air Crew, Inflight, CrashPad Listings'
    }
  },
  {
  path: '/featured',
  component: page('featured/index.vue'),
  name: 'featured'
  },
  {
    path: '/featured-signup/:id',
    component: page('featured/featureSignUp.vue'),
    name: 'featured-signup',
  },
  {
    path: '/featured-details',
    component: page('service-pages/FeaturedDetails.vue'),
    name: 'FeaturedDetails'
  },
	{
		path: '/cities',
		component: page('airports/index.vue'),
		name: 'cities'
	},
  { path: '/advertise',
    component: page('service-pages/Advertise.vue'),
    name: 'Advertise'
  },
  { path: '/autopay',
    component: page('service-pages/AutoPay.vue'),
    name: 'AutoPay'
  },
  { path: '/coming-soon',
    component: page('service-pages/ComingSoon.vue'),
    name: 'ComingSoon'
  },
  { path: '/emails',
  component: page('service-pages/EmailsSent.vue'),
  name: 'EmailsSent'
  },
  { path: '/fees',
    component: page('service-pages/FeeExplanation.vue'),
    name: 'FeeExplanation'
  },
  { path: '/help',
    component: page('service-pages/Help.vue'),
    name: 'Help'
  },
  { path: '/house-rules',
    component: page('service-pages/HouseRules.vue'),
    name: 'HouseRules'
  },
  { path: '/image-requirements',
    component: page('service-pages/ImageRequirements.vue'),
    name: 'ImageRequirements'
  },
  { path: '/policies',
  component: page('service-pages/Policies.vue'),
  name: 'Policies'
  },
  { path: '/problems',
    component: page('service-pages/Problems.vue'),
    name: 'Problems'
  },
  { path: '/quality-listings',
    component: page('service-pages/QualityListings.vue'),
    name: 'QualityListings'
  },
  { path: '/refund-policies',
  component: page('service-pages/RefundPolicies.vue'),
  name: 'RefundPolicies'
  },
  { path: '/tenant-management',
    component: page('service-pages/TenantManagement.vue'),
    name: 'TenantManagement'
  },
  { path: '/tenant-messaging',
    component: page('service-pages/TenantMessaging.vue'),
    name: 'TenantMessaging'
  },
  { path: '/what',
  component: page('service-pages/WhatIsACrashPad.vue'),
  name: 'WhatIsACrashPad'
  },
  { path: '/why',
  component: page('service-pages/WhyListCrashPad.vue'),
  name: 'WhyListCrashPad'
  },
  { path: '/_template',
  component: page('service-pages/_TEMPLATE.vue'),
  name: 'Template'
  },
  { path: '/404', component: page('errors/404.vue') },
	{
		path: '/:page',
		name: 'pages',
		component: page('page.vue')
	},
  { 
    path: '*', 
    component: page('errors/404.vue') 
  },
];
